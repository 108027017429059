@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin: auto;
    font-family: 'Montserrat', sans-serif;
    overflow: -moz-hidden-unscrollable;
    height: 100vh;
}

::-webkit-scrollbar {
    display: none;
}

::-webkit-input-placeholder {
    color: #CECECE;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

::-moz-placeholder {
    color: #CECECE;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

::-ms-placeholder {
    color: #CECECE;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

::placeholder {
    color: #CECECE;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.w-100 {
    width: 100%;
}

.instructions-img {
    height: 15vh;
    width: auto;
}

.welcome-img {
    border-radius: 15px;
}

.instructions-title-new {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.instructions-text-new {
    text-align: center;
}

.instructions {
    width: 100vw;
    /* height: calc(100vh - 65px); */
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 2000;
    background-color: #564CAC;
    color: white;
    overflow: auto;
}

.instructions::-webkit-scrollbar {
    display: none;
}

.phone-input {
    padding: 15px;
}

.confirm-digit {
    border: none;
    border-bottom: black 1px solid;
    width: 20px;
    margin: 0px 5px 10px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    outline: none;
}

.clear-btn {
    position: absolute;
    right: 60px;
    border: none;
    border-radius: 50px;
    padding: 5px 10px;
    background-color: #c2d3ed;
    color: #564cac;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10px;
    font-weight: 600;
}

.PhoneInputInput {
    border: none !important;
    border-bottom: solid 1.5px #cecece !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    font-family: 'Montserrat', sans-serif !important;
    transition: all 0.25s ease-out !important;
}

.PhoneInputInput:focus {
    border: none !important;
    border-bottom: solid 2px #5799ff !important;
    outline: none !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    font-family: 'Montserrat', sans-serif !important;
}

.PhoneInputInput:active {
    border: none !important;
    border-bottom: solid 2px #5799ff !important;
    outline: none !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    font-family: 'Montserrat', sans-serif !important;
}

.new-container {
    width: 100vw;
    min-height: 85vh;
    margin: 0;
    top: 15vh;
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 15px;
    border-radius: 45px 45px 0px 0px;
    background-color: white;
    padding: 15px 30px 15vh 30px;
    box-sizing: border-box;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    min-height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: all 0.5s ease-in;
}

.confirm-container {
    width: 100vw;
    min-height: 85vh;
    margin: 0;
    top: 15vh;
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 45px 45px 0px 0px;
    background-color: white;
    padding: 15px 30px 5vh 30px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: all 0.5s ease-in;
    box-sizing: border-box;
}

.filters {
    width: 100%;
    min-height: 100vh;
    opacity: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    transition: all 0.75s ease-in;
    position: fixed;
    top: 0;
    z-index: 5;
    background-color: white;
    left: 0;
    padding: 43px 30px 30px 30px;
    box-shadow: 0 0px 10px rgba(50, 50, 93, 0.15);
    /* border-radius: 0px 0px 35px 35px; */
    box-sizing: border-box;
    overflow: auto;
}

.filters::-webkit-scrollbar {
    display: none;
}

.filters-hidden {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: fixed;
    top: 55px;
    z-index: 999;
    background-color: white;
    left: 1vw;
    padding: 0;
    opacity: 0;
    height: 0px;
    border-radius: 0px;
    overflow: hidden;
    transition: all 0.75s ease-out;
}

.form {
    height: auto;
    width: 100vw;
    border-radius: 35px 35px 0px 0px;
    padding: 10px 30px 30px 30px;
    padding-bottom: calc(15vh + 30px);
    /* margin-top: -10vh; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: #FFFFFF;
    overflow: auto;
    box-sizing: border-box;
    transition: all 1s ease;
}

.form-animation-wrapper {
    width: calc(100vw - 60px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.form-price-wrapper {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
}

.form-price {
    color: white;
    font-size: 22px;
    margin-left: 5px;
    font-weight: 600;
    border-radius: 7px;
    padding: 5px;
    background-color: #f06424;
    box-shadow: 3px 3px 3px 2px #00000017;
}

.form-original-price {
    font-weight: 400;
    line-height: 24px;
    color: white;
    text-decoration: line-through;
    text-align: right;
    border-radius: 5px;
    background-color: #564cac;
    max-width: fit-content;
    padding: 2px 5px;
    margin-bottom: 10px;
}

.form-promotion-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    color: #191444;
}

.form-line {
    width: 55px;
    height: 5px;
    background-color: #E6E6E6;
    margin-bottom: 30px;
    border-radius: 50px;
}

.login-logo {
    margin: auto;
    margin-top: 5vh;
    /* width: 25vw; */
    width: auto;
    /* height: auto; */
    height: 58px;
    max-width: 350px;
    /* z-index: 999; */
    position: relative;
}

.banner {
    position: fixed;
    z-index: 1;
    background-image: linear-gradient(180deg, #564CAC, #2B2656);
    height: 35vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
}

p {
    font-size: medium;
}

.no-m {
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.m-5 {
    margin-left: 5vw;
    margin-right: 5vw;
}

.m-t-10 {
    margin-top: 10px;
}

.m-l-10 {
    margin-left: 10px;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.space-between {
    justify-content: space-between;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.w-45p {
    width: 45%;
}

.col-2 {
    width: 50%;
}

.col-3 {
    width: 33%;
}

.col-4 {
    width: 25%;
}

.promotion-description {
    text-align: left;
}

.instructions-image {
    margin-top: 25px;
    width: auto;
    height: 40vh;
    position: absolute;
    top: 0;
}

.faq-wrapper {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.question-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 1s;
}

.question {
    color: #564CAC;
    font-size: 16px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 600;
}

.answer {
    font-size: 14px;
    color: #191444;
    width: 100%;
    margin: 0px;
    font-weight: 500;
}

.line-seperator {
    width: 100%;
    margin-top: 10px;
    border-bottom: #f0642238 1px solid;
    transition: all 1s;
}

.instructions-title {
    font-size: 5.5vw;
    font-weight: 600;
    max-width: calc(100vw - 60px);
    margin: 0;
}

.instructions-text-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 70px; */
    margin-bottom: 20px;
}

.instructions-text {
    font-size: 4.3vw;
    width: 80%;
    font-weight: 500;
}

.instructions-small-text {
    font-size: 3.5vw;
    width: 80%;
    margin: 10px 0px 5px 10px;
}

.instructions-next-button {
    width: 80%;
    background-color: white;
    color: #564CAC;
    font-size: medium;
    font-weight: 600;
    padding: 10px 0px;
    border-radius: 4px;
    position: fixed;
    bottom: 110px;
    height: 40px;
    box-sizing: border-box;
}

.instructions-bottom-wrapper {
    /* position: absolute; */
    /* bottom: 50px; */
    margin-top: 20px;
    width: 80%;
    padding-bottom: 50px;
}

.instructions-circle-wrapper {
    width: 23.333%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.instructions-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.instructions-btn {
    background-color: white;
    color: #564CAC;
    font-size: medium;
    font-weight: 600;
    padding: 10px 0px;
    border-radius: 4px;
    bottom: 110px;
    height: 40px;
    box-sizing: border-box;
    width: 48%;
}

.iphone {
    margin-bottom: 5vh;
}

.not-float {
    position: static;
    margin-bottom: 65px;
    margin-top: 20px;
}

.icon-instructions-container {
    max-width: calc(100vw - 60px);
}

.text-left {
    text-align: left;
}

.menu-btn {
    position: fixed;
    right: 30px;
    top: 7vh;
    height: 26px;
    width: 26px;
}

.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    /* width: 100vw; */
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: 2499;
}

.menu {
    height: 100vh;
    width: 68.5vw;
    max-width: 330px;
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2500;
    padding: 25vh 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 1s;
    opacity: 1;
}

.menu-close {
    position: absolute;
    top: 7vh;
    right: 50px;
}

.menu-user-profile-img {
    position: absolute;
    top: 7vh;
    left: 50px;
    height: 25px;
    width: 25px;
    border-radius: 100px;
}

.menu-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16.5px;
    color: black;
    font-weight: 500;
    text-decoration: none;
    border: none;
    background-color: transparent;
    padding: 0;
    text-align: left;
    margin-bottom: 6vh;
}

.menu-option-icon {
    margin-right: 20px;
    max-height: 27px;
}

.empty-feed-background {
    /* width: 100vw;
    height: calc(100vh - 65px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background-color: #564CAC; */
    color: #191444;
}

.skip-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 105px;
    height: 75px;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    color: white;
    font-size: large;
    font-weight: 600;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
}

.login-screen {
    width: 100vw;
    min-height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 997;
    position: fixed;
    top: 0;
    background-size: cover;
    color: #CBCBCB;
    position: relative;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.feed {
    width: 100vw;
    min-height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 997;
    position: fixed;
    top: 0;
    background-size: cover;
    color: #CBCBCB;
    position: relative;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.login-container {
    margin: 0;
    margin-top: -20vh;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 15px;
    border-radius: 45px 45px 0px 0px;
    background-color: white;
    padding: 15px 30px 30px 30px;
    box-sizing: border-box;
    position: relative;
    z-index: 999;
    min-height: 80vh;
}

.login-image {
    width: 70vw;
    max-width: 500px;
}

.profile-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.profile-image {
    width: 50px;
    border-radius: 50px;
    position: absolute;
    right: 0;
}

.save-money-image {
    width: 80%;
}

.money-saved {
    font-size: 30px;
    font-weight: 700;
    color: #564cac;
    margin-bottom: 0;
}

.zcredits-icon {
    width: 15vw;
    max-width: 64px;
    height: auto;
    margin-right: 10vw;
}

.data {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 26px;
    color: #f06424;
}

.block-title {
    font-weight: 500;
    font-size: large;
    width: 100%;
    padding: 15px 0px;
    text-align: center;
}

.iframe-profile-wrapper {
    margin-left: -30px;
    margin-bottom: 30px;
}

.slider-wrapper {
    /* width: 100vw; */
    height: 80vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.carousel-image {
    width: 90vw;
}

.datablock {
    width: auto;
    padding: 0px 15px;
    height: 60px;
    background-color: #efefef;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.purchase-btn-wrapper {
    width: calc(100vw - 30px);
    max-width: 630px;
    padding: 60px 0px 15px;
    position: fixed;
    bottom: 65px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    z-index: 55;
    background-color: white;
}

.login-headline {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    text-transform: capitalize;
    color: #191444;
    margin-bottom: 15px;
}

.purchase-message {
    font-size: 13px;
    text-align: center;
    color: black;
    margin: 0;
}

.terms-link {
    font-size: 12px;
    text-align: center;
    margin: 0;
}

.terms-highlight {
    color: #ced4da;
    text-decoration: underline;
    margin: 0px 5px;
}

.phone-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}

.phone-back-button {
    position: absolute;
    top: 30px;
    right: 15px;
    border: none;
    background-color: transparent;
    color: #564cac;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.phone-back-arrow {
    height: 14px;
    margin-left: 5px;
}

.phone-text-message {
    font-weight: 500;
}

.highlighted-text {
    color: #564cac;
}

.bm-burger-bars {
    background: transparent;
}

.bm-morph-shape {
    fill: #564cac;
}

.bm-item {
    display: flex;
}

.redirect-wrapper {
    display: flex;
    width: 100vw;
    padding: 15px 30px;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding-bottom: 60px;
}

.redirect-row {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 100%;
    margin-bottom: 15px;
    font-weight: 600;
    border: #564cac 2px solid;
    border-radius: 60px;
    padding: 5px 10px;
    box-sizing: border-box;
    align-items: center;
}

.reversed {
    background-color: #564cac;
    color: white;
}

.button {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    outline: none;
    border: none;
    min-height: 45px;
    padding: 0;
    color: #564CAC;
    font-family: 'Montserrat', sans-serif;
}

.purple {
    color: white;
    background-color: #564cac;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 0;
    margin-top: 20px;
}

.orange {
    color: white;
    background-color: #f06424;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 10px;
    /* margin-top: 20px; */
}

.gray {
    color: black;
    background-color: #cecece;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 10px;
}

.login-button-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 40px;
}

.facebook {
    background-color: #F4F3F8;
    border: 1px #564cac solid;
}

.facebook:active {
    background-color: #FFFFFF;
}

.google {
    margin-top: 20px;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, .3);
}

.google:active {
    margin-top: 20px;
    background-color: #FFFFFF;
}

.text {
    font-size: 16px;
    text-align: left;
    font-weight: 600;
    margin: 0;
    margin-left: 11px;
}

.smaller-icon {
    width: 20px;
    height: 20px;
    transition: all 1s;
}

.icon {
    width: 26px;
    height: 26px;
    transition: all 1s;
}

.larger-icon {
    width: 32px;
    height: 32px;
    transition: all 1s;
}

.x-larger-icon {
    width: 42px;
    height: 42px;
    transition: all 1s;
}

.form-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0px;
    z-index: 1000;
    /* padding-top: 15vh; */
}

.X {
    position: absolute;
    top: 5px;
    right: 5px;
}

.form-text {
    margin-bottom: 15px;
    width: 100%;
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    margin-top: 0px;
}

.form-second-title {
    margin-bottom: 15px;
    width: 100%;
    /* text-align: center; */
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;
}

.form-img {
    /* margin-top: 15%; */
    min-width: 100px;
    min-height: 75px;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}

.qr-code {
    margin-top: 10px;
    border: 5px black solid;
    border-radius: 4px;
    padding: 5px;
}

.ad-container-form {
    background-color: #F6F6F6;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    text-align: left;
    position: relative;
}

.purchased-ad-link {
    height: 100%;
    width: calc(100% - 90px);
    left: 0;
    top: 0;
    position: absolute;
}

.report-form {
    width: 100%;
}

.report-form-label {
    font-size: small;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
}

.report-message {
    margin-top: 15px;
    width: 100%;
    border: 1px solid #ced4da;
    border-width: 0px 0px 1px 0px;
    font-family: 'Montserrat';
    font-size: 14px;
}

.report-submit-button {
    margin-top: 10px;
    background-color: #564cac;
    color: white;
    font-weight: 600;
    width: 100%;
    padding: 15px;
    border-radius: 25px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.terms-of-service {
    font-size: small;
    width: 80%;
}

.spacer {
    height: 30px;
}

.spacer-small {
    height: 25px;
}

.navbar {
    display: flex;
    flex-wrap: nowrap;
    /* padding: 0px 10px 0px 10px; */
    width: 100vw;
    height: 65px;
    align-items: center;
    align-content: stretch;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.09);
    z-index: 4;
}

.navLink {
    width: 20%;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: xx-small;
    justify-content: center;
    font-weight: bold;
    text-decoration: none;
    transition: all 1s;
}

.selectedLink {
    padding-bottom: 45px;
}

.selectedLinkImage {
    border-radius: 200px;
    margin-bottom: 7px;
    background-color: #564CAC;
    padding: 7px;
    border: 6px solid white;
    box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.09);
}

.app-wrapper {
    width: 100vw;
}

.new-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.new-header-text {
    width: 100%;
    text-align: left;
    margin: 15px 0px;
    font-weight: 600;
}

.new-search-div {
    background-color: #F6F6F4;
    border-radius: 100px;
    width: calc(100vw - 60px);
    max-width: 630px;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* max-width: 315px; */
    padding: 4.5px 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 5px;
    z-index: 3;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px #00000024;
}

.search-on-map {
    box-shadow: 0px 3px 6px #00000024;
    max-width: none;
}

.content-wrapper {
    max-width: 630px;
    width: 100%;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
    -ms-overflow-style: none;
    position: relative;
}

.content-wrapper::-webkit-scrollbar {
    display: none;
}

.new-search-input {
    background-color: transparent;
    border: none;
    width: 75%;
    height: 100%;
    font-size: 18px;
    color: #000000;
}

.new-search-input:focus {
    outline: none;
}

.new-filter-icon {
    background-color: white;
    width: 36px;
    height: 36px;
    border-radius: 100px;
    background-size: 21px;
    background-repeat: no-repeat;
    background-position: center;
}

.header-wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
}

.header-banner {
    background-color: #564cac;
    padding: 15px 0px;
    height: 82px;
    box-sizing: border-box;
}

.banner-logo {
    width: auto;
    height: 90%;
}

.banner-right {
    position: absolute;
    right: 10vw;
    top: 25px;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 15px;
    box-sizing: border-box;
    /* z-index: 999; */
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100vw;
    /* height: 65px; */
    background-color: white;
}

.header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-text {
    margin-bottom: 5px;
    margin-top: 5px;
    /* color: #616E7C; */
    font-weight: 500;
}

.filter-icon-wrapper {
    position: fixed;
    background-color: #564CAC;
    border-radius: 25px;
    padding: 5px;
    box-shadow: 0 3px 6px #000000;
    bottom: 15vh;
    right: 5vw;
    z-index: 997;
    height: 32px;
    width: 32px;
    align-items: center;
    justify-content: center;
    display: flex;
    /* transition: all 1s; */
}

.filter-toggle {
    height: 26px;
    width: 26px;
}

.filter-section-title-row {
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.filter-title {
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
    color: black;
    text-align: left;
    height: 22px;
}

.preferences-img {
    width: 80%;
    max-width: 300px;
    height: auto;
}

.logout-div {
    width: calc(50% - 5px);
    height: 22px;
    margin-top: 5px;
    font-weight: bold;
    white-space: nowrap;
    text-align: right;
    font-size: medium;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.logout-icon {
    margin-left: 5px;
    width: 22px;
    height: 22px;
}

.filter-type-title {
    font-size: small;
    font-weight: 500;
    white-space: nowrap;
    text-align: left;
    margin-bottom: 15px;
    margin-top: 10px;
}

.age-form {
    border: 1px solid #CECECE;
    border-width: 0px 0px 1px 0px;
    height: 38px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 45%;
}

.live-filter-wrapper {
    width: 45%;
}

.checkmark-wrapper {
    height: 30px;
    width: 30px;
    background-color: #564cac;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-right: 15px;
}

.checkmark-icon {
    height: 20px;
    width: 20px;
}

.age-box {
    border: 0px solid transparent;
    border-width: 1px;
    height: 36px;
    width: 40%;
    padding: 0px 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    max-width: 160px;
    text-align: center;
}

.age-control {
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 32px;
    width: 45px;
    height: 38px;
    line-height: 38px;
    padding: 0px 15px;
    box-sizing: border-box;
}

.filters-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.apply-filters-btn {
    width: 100%;
    margin: auto;
    margin-top: 30px;
    background-color: #564CAC;
    color: white;
    border-radius: 8px;
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: medium;
}

.discover-city {
    color: white;
    font-size: xx-large;
    font-weight: bold;
    position: absolute;
    bottom: 10%;
    left: 5vw;
    text-shadow: 1px -1px 23px black;
}

.popular-tags {
    display: flex;
    flex-direction: row;
    width: 95vw;
    scroll-behavior: smooth;
    overflow: scroll;
    padding-top: 5px;
    padding-bottom: 5px;
    position: -webkit-sticky;
    position: sticky;
    top: 5px;
    z-index: 3;
    background-color: white;
    align-items: center;
    -ms-overflow-style: none;
    border-radius: 50px;
    padding-left: 15px;
    margin-left: -10px;
    /* transition: 'all 1s ease'; */
}

.popular-tags::-webkit-scrollbar {
    display: none;
}

.discover-tag {
    margin: 3px 2.5px;
    font-size: x-small;
    font-weight: 600;
    color: #16123B;
    text-transform: uppercase;
    /* background-color: #11cdef; */
    background-color: #F6F6F6;
    padding: 2px 11px;
    border-radius: 50px;
    margin-bottom: 5px;
    white-space: nowrap;
}

.test {
    width: 300px;
    height: 200px;
    background-color: black;
}

.container {
    padding-right: 5%;
    padding-left: 5%;
    width: 90%;
    margin-top: 140px;
}

.map {
    padding-right: 0;
    padding-left: 0;
    width: 100vw;
    margin-left: -30px;
    margin-top: -15px;
    height: calc(100vh - 65px)
}


/* .map-top-content {
    height: ;
} */

.discover-row {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.discover-back {
    color: #564CAC;
    font-weight: 600;
}

.discover-row-tag {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
}

.discover-view-all {
    color: #564cac;
    font-size: 14px;
    font-weight: 600;
}

.discover-row-ads {
    display: flex;
    flex-direction: row;
    width: 90vw;
    margin-left: -20px;
    padding-left: 10px;
    height: auto;
    padding-bottom: 10px;
    scroll-behavior: smooth;
    overflow: scroll;
    -ms-overflow-style: none;
}

.discover-row-ads::-webkit-scrollbar {
    display: none;
}

.discover-row-ads-selected {
    display: flex;
    flex-direction: row;
    width: calc(100vw - 30px);
    margin-left: -15px;
    max-width: 630px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.discover-ad {
    min-width: 152px;
    height: 168px;
    color: white;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    text-align: left;
    margin-right: 10px;
    border-radius: 7px;
    position: relative;
}

.discover-ad-image {
    width: 100%;
    height: 99px;
    background-position: center;
    background-size: cover;
    border-radius: 8px 8px 0px 0px;
}

.discover-ad-selected {
    min-width: 165px;
    width: 300px;
    max-width: 48%;
    height: 250px;
    color: white;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    text-align: left;
    border-radius: 15px;
    position: relative;
    margin-bottom: 10px;
    background-position: center;
    background-size: cover;
    overflow: hidden;
}

.dicover-badges {
    position: absolute;
    top: 13px;
    left: 13px;
}

.selected-discover-text-wrapper {
    position: absolute;
    bottom: 13px;
    left: 13px;
}

.purple-line {
    height: 4px;
    width: 100%;
    background-color: #564CAC;
    border-radius: 100px;
    margin-bottom: 5px;
}

.selected-discover-name {
    font-size: 16px;
    font-weight: 600;
}

.selected-discover-promotion {
    font-size: 14px;
    font-weight: 500;
}

.selected-discover-price-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.selected-discover-ad-original-price {
    color: #AEAEAE;
    text-decoration: line-through;
    font-size: 13px;
    font-weight: 600;
}

.selected-discover-ad-price {
    color: white;
    font-size: 20px;
    font-weight: 500;
}

.discover-info-container {
    width: calc(100% - 20px);
    color: #191444;
    height: 69px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    bottom: 0;
    box-shadow: 0px 1px 6px #00000024;
    border-radius: 0px 0px 8px 8px;
    position: relative;
}

.discover-ad-name {
    font-size: 15px;
    font-weight: 600;
}

.discover-ad-promotion {
    color: #777780;
    font-size: 14px;
}

.discover-ad-icon-row {
    width: 100% - 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: smaller;
    align-items: center;
    margin-bottom: 4px;
}

.discover-icon {
    width: 11px;
    height: 11px;
}

.feed {
    max-width: 600px;
    /* margin: auto; */
}

.feed-ad {
    border-bottom: solid #80808021 1px;
    position: relative;
    margin-top: 15px;
    margin-left: -5vw;
    margin-right: -5vw;
    opacity: 1;
    transition: all 2s ease-in;
}

.new-feed-ad {
    width: 100%;
    margin-top: 36px;
}

.feed-ad-header {
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    width: 88%;
    justify-content: space-between;
    align-items: center;
    font-size: medium;
    font-weight: 700;
    margin: auto;
}

.feed-ad-report-btn {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 15px;
    left: 5%;
}

.new-share-button {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 15px;
    right: 5%;
}

.share-button {
    background-position: 50% 50%;
    display: flex;
    flex-direction: row;
    background-size: auto 26px;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 10px;
    right: 10px;
    position: absolute;
}

.feed-ad-img {
    margin: auto;
    position: relative;
    width: 90%;
    padding-top: 90%;
    background-position: 50% 100%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
    transition: all 1.5s ease;
}

.new-feed-ad-img {
    margin: auto;
    position: relative;
    width: 100%;
    max-width: 630px;
    max-height: 410px;
    height: 60vw;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    overflow: hidden;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09); */
    transition: all 1.5s ease;
}

.selected-feed-ad-img {
    /* transform: translateX(400px); */
    transform: scale(0);
}

.feed-ad-promotion-container {
    color: white;
    text-shadow: 2px 2px 2px #000000;
    background-color: #00000085;
    border-radius: 0px 0px 15px 15px;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0px;
}

.feed-ad-text-info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin: 10px 0px;
    margin-left: 5vw;
    font-size: medium;
    font-weight: 400;
}


/* .feed-ad-name{
} */

.feed-ad-promotion {
    margin-top: 3px;
}

.feed-ad-number-info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: medium;
    font-weight: normal;
    padding-bottom: 5px;
}

.feed-ad-price {
    margin-left: 5vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.discount {
    text-decoration: line-through #F5365C;
    margin-right: 5px;
    color: #F5365C;
    font-size: smaller;
}

.feed-ad-time-new {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.feed-ad-time-icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
}

.feed-ad-age {
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 5vw;
}

.new-feed-ad-footer {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: -60px;
    background-color: white;
    border-radius: 10px;
    position: relative;
    width: 90%;
    max-width: 576px;
    max-height: 270px;
    padding: 12px 15px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09); */
}

.hover-badges {
    position: absolute;
    top: -40px;
}

.live-indicator {
    position: absolute;
    top: -40px;
    height: 27.4px;
    right: 15px;
}

.badge {
    background-color: #564CACCC;
    color: white;
    margin-left: 7px;
    padding: 4.5px;
    box-sizing: border-box;
    border-radius: 4px;
}

.first-badge {
    margin-left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.badge-icon {
    height: 14px;
    width: 14px;
    margin-right: 5px;
}

.payment-selector-button-wrapper {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #564cac;
    border-radius: 6px;
    height: 94px;
    box-sizing: border-box;
    font-weight: 600;
    position: relative;
}

.tick {
    position: absolute;
    bottom: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
}

.z-credits-text {
    padding: 0;
    margin: 0;
}

.z-credits-value {
    font-size: 26px;
    color: #564cac;
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.new-ad-name {
    font-weight: bold;
    color: #191444;
    font-size: 20px;
    margin: 0;
    text-align: left;
    max-width: 50%;
}

.new-icon {
    width: 16px;
    height: 16px;
}

.new-promotion-text {
    margin: 0;
    text-align: left;
    margin-top: 5px;
    font-weight: 500;
}

.new-feed-ad-tags {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: space-around;
    margin-left: 5.5px;
}

.new-feed-tag {
    margin: 3px 2.5px;
    font-size: x-small;
    font-weight: 600;
    color: #16123B;
    text-transform: uppercase;
    /* background-color: #11cdef; */
    background-color: #F6F6F6;
    padding: 2px 11px;
    border-radius: 50px;
    margin-bottom: 5px;
    white-space: nowrap;
}

.amount-left {
    font-size: 13px;
    color: #16123B;
    font-weight: 600;
    margin: 0;
}

.amount-left-bar-wrapper {
    min-width: 72px;
    width: 20vw;
    height: 5px;
    background-color: #F4F3F8;
    margin-left: 10px;
    border-radius: 100px;
}

.amount-left-fill {
    background-color: #564cac;
    height: 5px;
    border-radius: 100px;
}

.new-original-price {
    text-decoration: line-through;
    color: #CECECE;
    font-size: 13px;
    margin: 0;
    margin-right: 7px;
    font-weight: 600;
}

.new-original-price-percent {
    background-color: #564cac;
    color: white;
    border-radius: 50px;
    padding: 5px 7px;
    font-size: 13px;
    margin: 0;
    margin-right: 7px;
    font-weight: 600;
}

.new-price {
    font-size: 20px;
    margin: 0;
    font-weight: 600;
    background-color: #f06424;
    color: white;
    padding: 2px 5px;
    border-radius: 6px;
    box-shadow: 3px 3px 5px 0px #00000014;
}

.directions-icon {
    margin: 0px 13px;
    width: 16px;
    height: 16px;
}

.loader-wrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
    /* height: 100px; */
}

.pizza-loader {
    width: 50vw;
    height: auto;
    margin-top: 10vh;
}

.feed-ad-footer {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.feed-ad-footer-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin: auto;
    margin-top: 15px;
}

.feed-ad-tags {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: space-around;
    font-size: small;
    font-weight: bold;
}

.feed-tag {
    margin: 0px 3px;
    font-size: x-small;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    /* background-color: #11cdef; */
    background-color: #564CAC;
    padding: 2px 10px;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(50, 50, 93, .11);
    margin-bottom: 5px;
}

.feed-ad-purchase-button {
    /* background: url(./img_resources/icons/purchase-hollow.png); */
    background-position: 50% 50%;
    background-size: auto 26px;
    background-repeat: no-repeat;
}

.ad-icon {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    text-decoration: none;
}

.time {
    position: absolute;
    top: 30px;
    right: 30px;
    flex-direction: row;
    color: white;
    font-size: xx-large;
    font-weight: 600;
    text-shadow: 1px 1px 6px black;
}

.share-popup-wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2000;
    background-color: rgba(255, 255, 255, 0.445);
    transition: all 1.5s;
}

.share-popup {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100vw;
    height: 30vh;
    bottom: -130px;
    left: 0;
    background-color: white;
    box-shadow: 0 3px 6px rgba(50, 50, 93, .95);
    border-radius: 15px 15px 0px 0px;
    padding-bottom: 15px;
    font-size: medium;
    font-weight: 600;
    transition: all 1.5s ease-in;
    transform: translateY(-130px);
}

.share-popup-closed {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100vw;
    bottom: -130;
    left: 0;
    background-color: white;
    box-shadow: 0 3px 6px rgba(50, 50, 93, .95);
    border-radius: 15px 15px 0px 0px;
    padding-bottom: 15px;
    font-size: medium;
    font-weight: 600;
    transition: all 1.5s ease-out;
    transform: translateY(0px);
}

.feedback-icon-wrapper {
    width: 15vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
}

.feedback-icon {
    width: auto;
    height: 15vw;
}

.share-icons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.share-icon-wrapper {
    width: 15vw;
    height: 15vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.share-icon {
    width: 95%;
    height: 95%;
}

.single-ad {
    position: relative;
    width: 100%;
}

.single-ad-name {
    font-weight: 500;
    font-size: 24px;
    text-align: left;
}

.img-preview {
    width: 80vw;
    height: 80vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 11px;
    margin: auto;
}

.confirm-controls {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.large-circle-button {
    height: 69px;
    width: 69px;
    border-radius: 50px;
    background-color: #F4F5F7;
}

.social-images-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
}

.social-image {
    width: 25vw;
    height: 25vw;
    border-radius: 11px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 10px;
}

.single-ad-img {
    margin: auto;
    width: 100%;
    padding-top: 65%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09); */
    position: relative;
}

.single-ad-report-btn {
    width: 24px;
    height: 24px;
}

.single-ad-footer {
    margin-top: 15px;
}

.single-ad-promotion {
    font-weight: 700;
    font-size: 20px;
    margin: 0px;
    text-align: left;
}

.single-ad-badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border-radius: 4px;
    margin-left: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.single-ad-badge-background {
    background-color: #564cac;
    opacity: 0.1;
    position: absolute;
    border-radius: 4px;
    width: 100%;
    height: 100%;
}

.single-ad-badge-text {
    margin: 0px;
    color: #564cac;
    font-size: 14px;
    font-weight: 500;
}

.single-page-tags {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.amount-left-single-ad {
    font-size: 18px;
    color: #16123B;
    margin: 0;
}

.amount-left-bar-wrapper-single-ad {
    min-width: 150px;
    width: 35vw;
    height: 10px;
    background-color: #F4F3F8;
    margin-left: 10px;
    border-radius: 100px;
}

.amount-left-fill-single-ad {
    background-color: #564cac;
    height: 10px;
    border-radius: 100px;
}

.single-ad-address-new {
    font-size: 16px;
    line-height: 22px;
    text-align: left;
}

.single-ad-page-text-container {
    position: absolute;
    left: 30px;
    bottom: 20px;
    font-size: x-large;
    color: white;
    font-weight: bold;
    text-align: left;
}

.single-ad-promotion-new {
    font-size: x-large;
    color: white;
    font-weight: 600;
    text-align: left;
}

.single-ad-price {
    font-weight: 500;
    font-size: xx-large;
    text-align: right;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.single-ad-price-new {
    font-weight: 600;
    font-size: x-large;
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.single-ad-purchase {
    background-color: #564CAC;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    text-transform: capitalize;
    box-shadow: 0px 3px 6px #564CAC;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    width: calc(100vw - 60px);
    max-width: 630px;
    padding: 15px 0px;
    position: fixed;
    bottom: 80px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    z-index: 55;
}

.single-ad-discount {
    text-decoration: line-through #ca2f2feb;
    margin-right: 10px;
    color: #ca2f2feb;
    font-size: smaller;
}

.single-ad-footer-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    margin-top: 15px;
}

.single-ad-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    margin: 15px 0px;
}

.single-ad-map {
    width: 100%;
    height: 30vh;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 10px;
}

.single-map-ad {
    width: 100%;
    height: 30vh;
    /* position: relative; */
    border-radius: 8px;
}

.map-test {
    height: 30vh;
    background-color: #564CAC;
    border-radius: 8px;
    width: 100%;
    position: relative;
}

.no-ads-text {
    margin-top: 15vh;
    font-weight: 600;
    font-size: 18px;
}

.single-ad-map-text {
    position: absolute;
    top: 50px;
    left: 5vw;
    text-align: left;
    font-size: x-large;
    font-weight: 500;
    z-index: 52;
}

.map-overlay {
    background-color: white;
    opacity: 0.3;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 51;
}

.overlay {
    /* background-color: #00000038; */
    background: linear-gradient(0deg, #00000092, transparent);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.flipped {
    background: linear-gradient(0deg, transparent, #00000040);
}

.single-ad-name-new {
    font-weight: 700;
}

.feed-button {
    width: 80vw;
    color: white;
    background-color: #564CAC;
    font-size: medium;
    font-weight: 600;
    padding: 10px 0px;
    border-radius: 4px;
    height: 40px;
    box-sizing: border-box;
    align-self: center;
}

.not-active-purchases {
    width: 100%;
    align-self: center;
    margin-top: 5vh;
}

.ad-page-purchase-text {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.purchase-btn-cta {
    margin-top: 5px;
    font-weight: 800;
    text-transform: uppercase;
}

.purchase-btn-grand {
    /* background-color: #407BFF; */
    background-color: #564CAC;
    height: 90px;
    width: 90px;
    border-radius: 100px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 1px 1px 12px #00000070;
}

.logout-text {
    font-weight: 700;
    font-size: larger;
    color: #564CAC;
    margin-right: 10px;
}

.logout-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected-ad-map-preview {
    min-height: 112px;
    width: 90vw;
    background-color: white;
    z-index: 997;
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 100px;
    left: 5vw;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 2px 10px #00000040;
}

.all-over {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
}

.map-img-thumbnail {
    height: auto;
    min-width: 73px;
    border-radius: 7px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.map-ad-info {
    width: calc(90vw - 105px);
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.map-promotion-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #191444;
}

.map-original-price {
    text-decoration: line-through;
    color: #CECECE;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
}

.map-price {
    color: #191444;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-left: 5px;
}

.map-promotion-name {
    color: #3A377A;
    font-weight: 600;
    font-size: 14px;
}

.purchase-date-text {
    /* font-size: 12px; */
    /* color: #AEAEAE; */
    font-size: 12px;
    color: white;
    background-color: #f06424ad;
    border-radius: 5px;
    padding: 3px 5px;
    box-sizing: border-box;
    text-align: center;
    align-self: flex-start;
    font-weight: 600;
    margin-top: 5px;
}

.map-badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border-radius: 4px;
    margin-left: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.map-badge-background {
    background-color: #564cac;
    opacity: 0.1;
    position: absolute;
    border-radius: 4px;
    width: 100%;
    height: 100%;
}

.map-badge-text {
    margin: 0px;
    color: #564cac;
    font-size: 11px;
    font-weight: 500;
}

.map-tags {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    margin-top: 15px;
    position: relative;
    z-index: 11;
}

.map-tags::-webkit-scrollbar {
    display: none;
}

.map-tag {
    margin: 3px 2.5px;
    font-size: x-small;
    font-weight: 600;
    color: #16123B;
    text-transform: uppercase;
    /* background-color: #11cdef; */
    background-color: #F6F6F6;
    padding: 2px 11px;
    border-radius: 50px;
    margin-bottom: 5px;
    white-space: nowrap;
}

.tokens-warpper {
    max-height: 20vh;
    overflow: auto;
}

.cc-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px;
    padding: 10px 15px;
    transition: all 0.5s ease-in-out;
    background-color: #f5f5f5;
    border-radius: 15px;
    width: 100%;
    max-width: calc(100vw - 120px);
}

.cc-type-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 50px;
    padding: 0px 10px;
    border-radius: 10px;
    background-color: white;
}

.cc-type {
    width: auto;
    max-width: 55px;
    margin: auto;
}

.cc-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.four-digits {
    margin: 0px;
    font-size: 15px;
    font-weight: 600;
    margin-right: 30px;
}

.exp-date {
    font-size: 12px;
    color: #AEAEAE;
    font-weight: 500;
}

.delete-cc {
    height: 25px;
}

.popup-modal-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    font-family: 'Montserrat', sans-serif;
}

.popup-modal {
    width: 90vw;
    max-width: 90vw;
    min-height: 60vh;
    background-color: white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.popup-modal-title {
    font-size: 20px;
    font-weight: 600;
}

.popup-modal-img {
    width: 100%;
}

.popup-modal-text {
    font-size: medium;
    font-weight: 500;
}

.popup-modal-btn {
    width: 90%;
    background-color: #564cac;
    color: white;
    border-radius: 25px;
    padding: 15px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    margin-top: 15px;
}

.popup-modal-btn-gray {
    width: 90%;
    background-color: #efefef;
    color: #564cac;
    border-radius: 25px;
    padding: 15px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    margin-top: 15px;
}

.popup-modal-cancel {
    width: 90%;
    background-color: transparent;
    border: none;
    padding: 15px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.iframe-wrapper {
    width: 100vw;
    height: auto;
    /* margin-top: 30px; */
    margin-bottom: -30px;
    overflow: auto;
}

.payment-iframe {
    width: 100vw;
    overflow: hidden;
    height: 280px;
    margin-top: -60px;
    margin-bottom: 150px;
}

.form-content-wrapper {
    /* display: flex;
    flex-direction: column;
    max-width: calc(100vw - 60px);
    align-items: center; */
    /* max-height: 80vh; */
    /* overflow: auto; */
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    align-items: center;
    /* max-height: 80vh; */
    /* overflow: auto; */
    background-color: white;
    max-width: 100vw;
    padding: 15px 30px 30px;
    height: auto;
    border-radius: 35px 35px 0px 0px;
    box-sizing: border-box;
}

.cover {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.test-payment {
    width: 100%;
}

.test-purchase-btn {
    background-color: #f06424;
    color: white;
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 600;
}

.payment-btns {
    width: 100%;
    height: 94px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding: 0px 10px; */
    box-sizing: border-box;
    margin-top: 20px;
    position: relative;
    transition: all 1s;
}

.cc-checkmark {
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: -8px;
    right: -8px;
}

.payment-methods-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.new-payment-method {
    width: 100%;
    height: 74px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: #564cac47; */
    /* border-top: #564cac 1px solid; */
    /* border-bottom: #564cac 1px solid; */
    padding: 0px 15px;
    box-sizing: border-box;
}

.new-payment-icon {
    width: 26px;
    height: 50px;
}

.cc-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.cc-field-label {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}

.cc-field {
    border: none;
    border-bottom: solid 1px #E6E6E6;
    height: 35px;
    color: black;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.cc-field:focus {
    outline: none;
}

.cc-half-width {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.pay-btn {
    color: white;
    background-color: #564CAC;
    width: 100vw;
    margin: 0px -30px;
    margin-top: 30px;
    /* margin-bottom: -30px; */
    padding: 15px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border: none;
    font-size: 16px;
    font-weight: 500;
    /* position: fixed; */
    /* bottom: calc(15vh + 30px); */
    bottom: 15vh;
    height: 60px;
}

.pay-btn-gray {
    color: white;
    background-color: #CECECE;
    width: 100vw;
    margin: 0px -30px;
    /* margin-top: 30px; */
    /* margin-bottom: -30px; */
    padding: 15px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border: none;
    font-size: 16px;
    font-weight: 500;
    /* position: fixed; */
    /* bottom: calc(15vh + 30px); */
    bottom: 15vh;
    height: 60px;
    -webkit-transform: translate(0);
}

.designed-payment-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 10px;
}

.hidden-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 10px;
    opacity: 0.5;
}

.payment-btn {
    border-radius: 6px;
    border: #E6E6E6 solid 1px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 45%;
    position: relative;
    transition: all 1s;
}

.cc-icon {
    height: 42px;
    width: auto;
}

.paypal-icon {
    width: 92px;
    height: auto;
}

.terms {
    text-align: left;
}

.terms>* {
    /* width: 100%; */
    text-align: left;
}

.terms ol {
    margin-left: 0px;
    padding-left: 0px;
}

.terms ul {
    padding-left: 15px;
}

.terms li strong {
    font-size: large;
}

.close-form {
    position: absolute;
    top: 35px;
    right: 35px;
    z-index: 50;
}

.post-purchase {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .col-1 {
        width: 8.33%;
    }
    .col-2 {
        width: 16.66%;
    }
    .col-3 {
        width: 25%;
    }
    .col-4 {
        width: 33.33%;
    }
    .col-5 {
        width: 41.66%;
    }
    .col-6 {
        width: 50%;
    }
    .col-7 {
        width: 58.33%;
    }
    .col-8 {
        width: 66.66%;
    }
    .col-9 {
        width: 75%;
    }
    .col-10 {
        width: 83.33%;
    }
    .col-11 {
        width: 91.66%;
    }
    .col-12 {
        width: 100%;
    }
    .new-icon {
        height: 2.5vw;
        width: 2.5vw;
        max-width: 32px;
        max-height: 32px;
    }
    .directions-icon {
        height: 2.5vw;
        width: 2.5vw;
        max-width: 32px;
        max-height: 32px;
    }
    .amount-left {
        font-size: 16px;
        color: #16123B;
        margin: 0;
        font-weight: 600;
    }
    .amount-left-bar-wrapper {
        width: 20vw;
        height: 5px;
        background-color: #F4F3F8;
        margin-left: 10px;
        border-radius: 100px;
    }
}